import React from "react"
import "./VehicleBio.scss"

interface IVehicleBio {
  body: string
}

interface IVehicleDetails {
  description: string
  heading: string
}

const VehicleBio: React.FC<IVehicleBio> = props => {
  const vehicleDetails: IVehicleDetails[] = []
  props.body.split("\n").forEach(vehicle => {
    if (vehicle.length > 0) {
      const arr = vehicle.split(" -")
      vehicleDetails.push({
        description: arr[1].trim(),
        heading: arr[0].trim(),
      })
    }
  })

  return (
    <div className="vehicle-bio f-body2 f-body-lite2">
      <div>
        {vehicleDetails.map((value, index) => {
          return (
            <div key={index} className="vehicle-bio__list">
              <div className="f-body2--bold">{value.heading}</div>
              <div className="vehicle-bio__description f-mono f-mono--lite">
                {value.description}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default VehicleBio

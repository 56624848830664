import AboutCollage from "@components/AboutCollage/AboutCollage"
import BannerCTA from "@components/BannerCTA/BannerCTA"
import Intro from "@components/Intro/Intro"
import Layout from "@components/Layout/Layout"
import SplitCard from "@components/SplitCard/SplitCard"
import "@styles/global.scss"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"

interface IHeroImage {
  childImageSharp: any
}
interface IData {
  backgroundHero: IHeroImage
  hero: IHeroImage
  whatWereAbout1: IHeroImage
  whatWereAbout2: IHeroImage
  inTheGarage: IHeroImage
}

export default () => {
  const PageTitle: string = "About the Garage | Hagerty Learning Garage"
  const metaDescription: string =
    "The Hagerty Learning Garage is an employee training and vehicle restoration facility in Traverse City, Michigan."

  const data: IData = useStaticQuery(graphql`
    {
      backgroundHero: file(relativePath: { eq: "heros/about.jpg" }) {
        childImageSharp {
          fixed(width: 1800, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      hero: file(relativePath: { eq: "heros/about.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1100, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      whatWereAbout1: file(relativePath: { eq: "what-were-about-1.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      whatWereAbout2: file(relativePath: { eq: "what-were-about-2.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      inTheGarage: file(relativePath: { eq: "in-the-garage.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const images: any[] = [
    data.whatWereAbout1.childImageSharp.fluid,
    data.whatWereAbout2.childImageSharp.fluid,
  ]

  return (
    <Layout
      metaPageTitle={PageTitle}
      metaDescription={metaDescription}
      darkTheme={true}
    >
      <Intro
        title="The Hagerty Learning Garage"
        body="Find the passion of the automotive enthusiast in our vehicles’ guts and glam."
        backgroundHero={data.backgroundHero.childImageSharp.fixed.src}
        hero={data.hero.childImageSharp.fluid}
      />
      <AboutCollage fluidImages={images}>
        <h2 className="f-h2">What we're about</h2>
        <p>
          Driving enables freedom and a return to being present. In the Learning Garage,
          we are bringing those experiences back to life, one project at a time.
        </p>
        <hr />
        <h3 className="f-h3">We love cars</h3>
        <p>
          At Hagerty, we’re fostering a community of car lovers. The thrill of
          the find is a feeling second only to the pulse of an engine’s rhythm
          while sitting in the driver’s seat. There’s a legacy to uphold, and we
          believe cars, the community that surrounds them, and the hands-on
          experience of driving them is worth keeping alive.
        </p>
        <p>
          Today, we are a company of automotive enthusiasts and experts, with
          offices in the US, the UK, and Canada, and while we’re the global
          leader in collector car insurance, we’ve moved beyond insurance to
          offer so much more.
        </p>
        <h3 className="f-h3">And we love to talk about them </h3>
        <p>
          Our Media team is a force to be reckoned with, made up of the best
          automotive journalists on the planet. Our Garage and the vehicles
          inside it are primed and ready to be in front of the camera at a
          moment’s notice for a shoot for Hagerty DIY or Redline Rebuild, two of
          our video series that have attracted over 1 million followers. <a href="https://www.hagerty.com/media">Join the movement</a>
        </p>
        <p>
            From immersive events and experiences to a robust digital community, Hagerty is dedicated to connecting auto enthusiasts with one another to support the growth and variety of the automotive industry. No two car lovers are alike, but we all have something in common. <a href="https://www.hagerty.com">Let’s drive together.</a>
        </p>
      </AboutCollage>
      <SplitCard
        title="In the Learning Garage"
        body="There’s always an exciting project happening around here, with vehicles from every era on site for restoration or employee learning opportunities. You can also peruse our vehicles up close and in person."
        fluidImg={data.inTheGarage.childImageSharp.fluid}
        rightAlignText={true}
      />
      <BannerCTA />
    </Layout>
  )
}

import ContentImage from "@components/ContentImage/ContentImage"
import { Link } from "gatsby"
import React from "react"
import "./SplitCard.scss"

interface ISplitcard {
  title: string
  body: string
  ctaLink: string
  ctaDescription: string
  fluidImg: any
  rightAlignText: boolean
}

const SplitCard: React.FC<ISplitcard> = props => {
  return (
    <article className="wrapper split-card--container">
      <div className="col col-2">
        <div className={props.rightAlignText ? "col--left" : "col--right"}>
          <ContentImage fluidImg={props.fluidImg} />
        </div>
        <div
          className={`${props.rightAlignText ? "col--right" : "col--left"}
      ${"col--content"}`}
        >
          <h2 className="f-h2">{props.title}</h2>
          <p className="f-body2">{props.body}</p>
          <Link to={props.ctaLink} className="f-link link--strong">
            {props.ctaDescription}
          </Link>
        </div>
      </div>
    </article>
  )
}

SplitCard.defaultProps = {
  rightAlignText: false,
}

export default SplitCard

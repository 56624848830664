import Img, { FluidObject } from "gatsby-image"
import React from "react"
import VehicleBio from "./VehicleBio/VehicleBio"
import "./Intro.scss"

interface IIntro {
  title: string
  body: string
  backgroundHero: string
  hero: FluidObject
  vehicleBio: boolean
}

const Intro: React.FC<IIntro> = props => {
  return (
    <section className="wrapper intro">
      <div
        className="intro--background-hero"
        style={{ backgroundImage: `url('${props.backgroundHero}')` }}
      />
      <div className="intro__content constrain-hero">
        <h1 className="f-h1 f-h-lite f-page-title">{props.title}</h1>
        {props.vehicleBio === true ? (
          <VehicleBio body={props.body} />
        ) : (
          <p className="f-body2 f-body-lite2">{props.body}</p>
        )}
      </div>
      <div className="intro__hero">
        <div className="intro__hero--center">
          <Img fluid={props.hero} />
        </div>
      </div>
    </section>
  )
}

Intro.defaultProps = {
  vehicleBio: false,
}

export default Intro

import ContentImage from "@components/ContentImage/ContentImage"
import React, { ReactChildren } from "react"
import "./AboutCollage.scss"

interface IAboutCollage {
  fluidImages: any[]
  children: ReactChildren
}

const AboutCollage: React.FC<IAboutCollage> = props => {
  return (
    <article className="wrapper about-collage">
      <div className="col col-2 col--large-gap col--align-start">
        <div className="col--left">{props.children}</div>
        <div className="col--right">
          {props.fluidImages.map((fluidImage, i) => {
            return <ContentImage key={i} fluidImg={fluidImage} />
          })}
        </div>
      </div>
    </article>
  )
}

export default AboutCollage
